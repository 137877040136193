'use client';
import React from 'react';
import { IntercomBubble } from '@mentimeter/intercom';
import { LoadingOrErrorPage } from '@mentimeter/__app-split-features/application/loading-or-error';
import { usePageError } from '@mentimeter/errors';

const FiveHundred = ({ error }: { error: Error }) => {
  usePageError({
    error,
    message: 'Something went wrong and got caught in root-error',
    feature: 'root-error',
  });

  return (
    <>
      <IntercomBubble />
      <LoadingOrErrorPage error="Something went wrong..." />
    </>
  );
};

export default FiveHundred;
